import React from 'react';

import {Container, Empty} from './styles';

const ContentHeader = (props) => {
    if (props.empty) {
        return (
            <Container $css={props.$css} className={props.className}>
                <Empty />
            </Container>
        );
    }
    return (
        <Container $css={props.$css} className={props.className}>
            {props.children}
        </Container>
    );
};

export default ContentHeader;
