export let photo = (props) =>
    props.style
        ? props.style
        : {
              display: 'inline-block',
              verticalAlign: 'middle',
              height: '100%',
              borderRadius: '50%',
              overflow: 'hidden',
              minWidth: props.width ? props.width : 'none',
          };

export let img = (props) => {
    let baseStyles = {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
    };

    let url = '';

    if (props.src && typeof props.src === 'string') {
        url = props.src.match(/\s/g) ? encodeURI(props.src) : props.src;
    }

    if (url && url.split(';').length > 2) {
        url = url.split(';').slice(1, url.split(';').length).join(';');
    }

    let imageStyles = Object.assign(
        {},
        props.src ? {backgroundImage: `url('${url}')`} : {},
        props.width ? {width: props.width} : {},
        props.height ? {height: props.height} : {},
    );

    return Object.assign(baseStyles, imageStyles, props.photoStyle);
};
