import {Z} from 'core/styles';
import styled from 'styled-components';

export function container(props) {
    return Object.assign(
        {
            position: 'relative',
            zIndex: Z.modal,
        },
        props.style,
    );
}

export let ModalContainer = styled.div`
    position: relative;
    z-index: ${Z.modal};
    ${(props) => (props.$css ? props.$css : '')};
`;
//${props => props.borderColor ? props.borderColor : "palevioletred"};
