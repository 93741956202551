'use client';
import React from 'react';

import {ButtonStyled} from './styles';

const SubmitButtonStyled = (props) => {
    const {
        handleClick = () => null,
        submitText = 'Submit',
        submittingText = 'Submitting...',
        successText = 'Submitted!',
        failText = 'Failed',
    } = props;
    const hasSucceeded = props.submitSucceeded && props.valid;
    const hasFailed = props.submitFailed && (props.error || !props.valid);
    const hasSubmitted = hasSucceeded || hasFailed;

    let text = props.submitting ? submittingText : submitText;

    if (hasSubmitted) {
        text = props.submitSucceeded ? successText : failText;
    }

    let disabled = false;

    if (hasSubmitted) {
        disabled = !(props.submitSucceeded && props.valid);
    } else {
        disabled = props.disabled || props.submitting || props.pristine;
    }

    return (
        <ButtonStyled
            {...props}
            $css={props.$css}
            className={props.className}
            onClick={handleClick}
            onMouseUp={props.handleMouseUp}
            disabled={disabled}
            type="submit">
            {text}
        </ButtonStyled>
    );
};

export default SubmitButtonStyled;
