import React from 'react';
import {Container} from './styles';

const CancelButton = (props) => {
    return (
        <Container {...props} onClick={props.handleClick}>
            {props.children || 'Cancel'}
        </Container>
    );
};

export default CancelButton;
