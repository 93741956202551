import {COLORS, darken, hexToRgb} from 'core/styles';
import styled from 'styled-components';

export const button = (props) => {
    return Object.assign(
        {
            cursor: 'pointer',
            backgroundColor: '#fff',
            border: `1px solid ${darken(COLORS.grayLight, 10)}`,
            fontSize: '11px',
            fontWeight: 'bold',
            display: 'block',
            width: '100%',
            maxWidth: '145px',
            padding: '15px 20px',
            color: COLORS.grayFont,
            textTransform: 'uppercase',
            textAlign: 'center',

            '&:active': {
                border: '1px solid rgba(0,0,0,0.28)',
                boxShadow: `inset 0 0 3px rgba(${hexToRgb(COLORS.grayFont)}, 0.4)`,
            },

            '&[disabled]': {
                opacity: 0.5,
            },
        },
        props.style,
    );
};

export const StyledButton = styled.button`
    cursor: pointer;
    background-color: #fff;
    border: 1px solid ${darken(COLORS.grayLight, 10)};
    font-size: 11px;
    font-weight: bold;
    display: block;
    width: 100%;
    max-width: 145px;
    padding: 15px 20px;
    color: ${COLORS.grayFont};
    text-transform: uppercase;
    text-align: center;

    &:active {
        border: 1px solid rgba(0, 0, 0, 0.28);
        box-shadow: inset 0 0 3px rgba(${hexToRgb(COLORS.grayFont)}, 0.4);
    }

    &:disabled {
        opacity: 0.5;
    }

    ${(props) => (props.$css ? props.$css : null)}
`;
