import {COLORS, bp} from 'core/styles';
import styled from 'styled-components';

export const ContainerStyles = styled.div`
    margin-bottom: 15px;
    max-width: 250px;
    width: 100%;
    position: relative;
    ${(props) => (props.$css ? props.$css : '')}
`;

const showError = (props) => {
    return props.meta ? !props.meta.active && props.meta.error : false;
};

export let InputStyled = styled.input`
    line-height: normal;
    padding: 10px;
    font-size: 13px;
    font-weight: bold;
    margin-right: 20px;
    margin-bottom: 10px;
    border-style: none;
    width: 100%;
    border-width: 1px;
    border-radius: 2px;
    color: ${COLORS.gray};
    box-shadow: ${(props) =>
        showError(props)
            ? 'inset 2px 2px 1px rgba(0,0,0,0.1), inset -1px -1px 1px rgba(0,0,0,0.1)'
            : 'box-shadow: inset 2px 2px 1px rgba(0,0,0,0.1), inset -1px -1px 1px rgba(0,0,0,0.1), inset 0 0 2px #e01e63'};

    ${bp('md')} {
        box-shadow: inset 2px 2px 1px rgba(0, 0, 0, 0.1),
            inset -1px -1px 1px rgba(0, 0, 0, 0.1);
    }

    & disabled: {
        opacity: 0.5;
    }
    ${(props) => (props.$css ? props.$css : '')}
`;
