import React, {useState, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';

import {selectCurrentUser} from 'features/users/selectors';
import {selectCurrentUserPermissions} from 'features/permissions/selectors';
import {intersection, get} from 'utils/helpers';
import {usePathname, useParams} from 'next/navigation';
import {selectAccountByIdNoWithRouter} from 'features/accounts/selectors';

let Auth = (props) => {
    const {authorize, permissions} = props;
    const user = useSelector(selectCurrentUser);
    const userPermissions = useSelector(selectCurrentUserPermissions);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const pathname = usePathname();
    const [shouldRenderComponent, setShouldRenderComponent] = useState(false);

    const {accountId} = useParams();
    const account = useSelector((state) =>
        selectAccountByIdNoWithRouter(state, accountId),
    );

    const checkAuth = () => {
        if (permissions && !Array.isArray(permissions)) {
            console.warn('"permissions" property must be an array', props.children);
        }

        if (permissions) {
            setShouldRenderComponent(
                intersection(permissions, userPermissions).length === permissions.length,
            );
        }

        if (authorize && user) {
            setShouldRenderComponent(authorize(user, account, pathname));
        }
    };

    useEffect(() => {
        checkAuth();
    }, []);

    if (!shouldRenderComponent) {
        return null;
    }

    return props.children;
};

export default Auth;
