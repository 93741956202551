import React from 'react';

import * as styles from './styles';

const Button = (props) => {
    return (
        <button
            style={styles.button(props)}
            type={props.type}
            disabled={props.disabled}
            onMouseUp={props.handleMouseUp}
            onClick={props.onClick}>
            {props.text}
            {props.children}
        </button>
    );
};

export default Button;
