'use client';
import React, {useRef, useEffect} from 'react';
import {connect} from 'react-redux';

import {hideModal} from 'features/modal/slice';

import {ModalContainer} from './styles';

let Modal = (props) => {
    useEffect(() => {
        const eventType = props.mousedown ? 'mousedown' : 'click';
        document.addEventListener(eventType, handleEvent, false);
        document.addEventListener('keydown', handleEvent, false);
        return () => {
            const eventType = props.mousedown ? 'mousedown' : 'click';
            document.removeEventListener(eventType, handleEvent, false);
            document.removeEventListener('keydown', handleEvent, false);
        };
    }, []);

    const myRef = useRef();

    const handleEvent = (e) => {
        if (e.key && e.key !== 'Escape') return;
        if (myRef.current.contains(e.target)) return;

        if (props.handleClose) {
            props.handleClose(e);
        } else {
            props.hideModal();
        }
    };
    return (
        <ModalContainer {...props} $css={props.$css} ref={myRef}>
            {props.children}
        </ModalContainer>
    );
};

export default connect(null, {hideModal})(Modal);
