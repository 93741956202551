'use client';
import {COLORS, darken, lighten} from 'core/styles';
import styled from 'styled-components';

const hasSubmitted = (props) => {
    const hasSucceeded = props.submitSucceeded && props.valid;
    const hasFailed = props.submitFailed && (props.error || !props.valid);
    return hasSucceeded || hasFailed;
};

const isDisabled = (props) => {
    if (hasSubmitted(props)) {
        return !(props.submitSucceeded && props.valid);
    } else {
        return props.disabled || props.submitting || props.pristine;
    }
};

const getStyles = (props) => {
    let styles = {
        backgroundColor: COLORS.primary,
        hoverBackgroundColor: lighten(COLORS.primary, 5),
    };

    const hasSubmittedVar = hasSubmitted(props);
    if (hasSubmittedVar && props.submitSucceeded && props.valid) {
        styles.backgroundColor = darken(COLORS.success, 40);
        styles.hoverBackgroundColor = darken(COLORS.success, 40);
    }

    if (hasSubmittedVar && !(props.submitSucceeded && props.valid)) {
        styles.backgroundColor = darken(COLORS.danger, 5);
        styles.hoverBackgroundColor = darken(COLORS.danger, 5);
    }
    return styles;
};

export const ButtonStyled = styled.button`
    cursor: pointer;
    min-width: 115px;
    text-transform: uppercase;
    background-color: ${(props) => getStyles(props).backgroundColor};
    color: #fff;
    font-size: 11px;
    opacity: ${(props) => (isDisabled(props) ? 0.5 : 1)};
    padding: 10px 15px;
    font-weight: 900;
    &:hover {
        background-color: ${(props) => getStyles(props).hoverBackgroundColor};
        cursor: pointer;
    }
    &:active {
        background-color: ${darken(COLORS.primary, 10)};
    }
    ${(props) => (props.$css ? props.$css : '')}
`;
