import {COLORS, bp} from 'core/styles';
import styled from 'styled-components';

export const Container = styled.div`
    padding: 30px 20px 15px 30px;
    display: block;
    margin: 0;
    background-color: #fff;
    font-weight: 500;
    color: ${COLORS.grayFont};
    font-size: 16px;
    height: 82px;

    ${bp('md')} {
        font-size: 22px;
    }
    ${(props) => (props.$css ? props.$css : '')};
`;

export let Empty = styled.div`
    width: 100px;
    height: 4px;
    filter: blur(8px);
    position: relative;
    top: 14px;
    left: 5px;
    opacity: 0.8;
    background-color: ${COLORS.grayFont};
`;
