import React from 'react';

import * as styles from './styles';

const Photo = (props) => {
    return (
        <div style={styles.photo(props)} onClick={props.handleClick}>
            <div style={styles.img(props)} />
        </div>
    );
};

export default Photo;
